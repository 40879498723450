import ActionNode = require("Everlaw/UI/ActionNode");
import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");
import BaseSingleSelect = require("Everlaw/UI/BaseSingleSelect");
import Dom = require("Everlaw/Dom");
import Icon = require("Everlaw/UI/Icon");
import TextBox = require("Everlaw/UI/TextBox");
import UI = require("Everlaw/UI");
import { Text, TextParams } from "Everlaw/UI/Validated";
import Widget = require("Everlaw/UI/Widget");

class SingleSelect<T extends Base.Object>
    extends BaseSingleSelect.SingleWithToggler<T>
    implements BaseSelect.Filtering<T, T>
{
    override equalsNew(existing: T, name: string) {
        return super.equalsNew(existing, name);
    }
    /** Allow the user to handle special keys if they want. */
    override handleKey(k: number, evt: KeyboardEvent) {
        return super.handleKey(k, evt);
    }
    override hasTextbox() {
        return true;
    }
    override setValueOnBlur() {
        this.tb.setValue(this.display(this._selected));
        this._filter("");
    }
}

module SingleSelect {
    /**
     * Widget that replaces the default SingleSelect styling by overlaying it with a `displayDiv`
     * when an element is selected.
     */
    export class SingleWithDisplay<T extends Base.Object>
        extends Widget
        implements UI.WidgetWithTextBox
    {
        selector: SingleSelect<T>;
        public displayDiv: HTMLElement;
        private name: HTMLElement;
        private dropdownIcon: Icon;
        private onSelect: (e: T) => void;
        constructor(params: BaseSingleSelect.Params<T>) {
            super();
            this.onSelect = params.onSelect;
            this.node = Dom.div({ class: "select-with-display" });

            // These parameters must be set for SingleWithDisplay to work.
            const requiredForSingleWithDisplayParams = {
                popup: params.popup || "after", // The styling breaks if popup is not set.
                selectOnSame: true, // This widget expects an element to always be selected
                textBoxWidth: params.textBoxWidth || "100%",
                onBlur: () => {
                    params.onBlur?.();
                    if (this.selector.getSelected()) {
                        this.toggleSelector(false);
                    }
                },
            };

            this.selector = new SingleSelect<T>({
                ...params,
                ...requiredForSingleWithDisplayParams,
            });
            Dom.place(this.selector, this.node);
            this.registerDestroyable(this.selector);

            this.displayDiv = Dom.create("div", this.node);
            this.name = Dom.create("h2", { class: "select-with-display-name" }, this.displayDiv);
            this.dropdownIcon = new Icon("caret-down-20", { parent: this.name });
            this.registerDestroyable(this.dropdownIcon);
            this.registerDestroyable(
                new ActionNode(this.displayDiv, {
                    onClick: () => this.toggleSelector(true),
                    makeFocusable: true,
                }),
            );
            Dom.hide(this.displayDiv);
        }
        private onBlur() {
            const s = this.selector.getSelected();
            if (s) {
                this.toggleSelector(false);
            }
        }
        select(element: T) {
            this.selector.select(element);
            // programmatically selecting the element won't trigger a blur unless the user already had
            // focus on the selector
            this.onBlur();
        }
        toggleSelector(toggle: boolean) {
            Dom.hide(this.displayDiv, toggle);
            Dom.show(this.selector, toggle);
            if (toggle) {
                this.selector.focus();
            } else {
                Dom.setContent(this.name, this.selector.getSelected().display());
                Dom.addContent(this.name, this.dropdownIcon.node);
            }
        }
        setTextBoxAriaLabel(ariaLabel: string) {
            this.selector.setTextBoxAriaLabel(ariaLabel);
        }
        setTextBoxLabelContent(labelContent: Dom.Content) {
            this.selector.setTextBoxLabelContent(labelContent);
        }
        setTextBoxLabelPosition(position: TextBox.LabelPosition) {
            this.selector.setTextBoxLabelPosition(position);
        }
    }

    export interface SingleWithCaptionsParams<T extends Base.Object>
        extends BaseSingleSelect.Params<T> {
        displayCaption: (e: T | string) => string;
    }
    export interface SingleWithSearchableCaptionsParams<T extends Base.Object>
        extends BaseSingleSelect.Params<T> {
        handleCaptions: boolean;
        matchCapStyles: (caps: string[]) => string[];
        getCaptionValues: (e: T | string, val: string) => Dom.Content[];
    }

    /**
     * This behaves the exact same as SingleSelect, but displays a caption for each option. It takes a
     * function as an argument that maps options to their captions.
     */
    export class SingleWithCaptions<T extends Base.Object> extends SingleSelect<T> {
        displayCaption: (e: T | string) => string;

        constructor(params: SingleWithCaptionsParams<T>) {
            super(params);
        }

        protected override prepRowElement(e: T | string): BaseSelect.Row {
            return {
                node: Dom.div(
                    {
                        class: "table-row action description common-option",
                    },
                    Dom.span({ class: "label-node" }, this.shortDisplay(e)),
                    Dom.div(
                        {
                            class: "label-node-caption",
                        },
                        this.displayCaption(e),
                    ),
                ),
                onDestroy: [],
            };
        }
    }

    export class SingleWithSearchableCaptions<T extends Base.Object> extends SingleSelect<T> {
        constructor(params: SingleWithSearchableCaptionsParams<T>) {
            super(params);
        }

        protected override prepRowElement(e: T | string): BaseSelect.Row {
            return {
                node: Dom.div(
                    { class: "table-row action description common-option" },
                    Dom.span(
                        { class: "label-node", style: { display: "inline-block" } },
                        this.shortDisplay(e),
                    ),
                    Dom.div({ class: "label-node-caption" }, ""),
                ),
                onDestroy: [],
            };
        }
    }

    export interface SingleSelectWithValidatedInputParams<T extends Base.Object>
        extends BaseSingleSelect.Params<T> {
        textParams: TextParams;
    }

    export class SingleSelectWithValidatedInput<T extends Base.Object> extends SingleSelect<T> {
        validatedInput!: Text;
        private readonly textParams: TextParams;

        constructor(params: SingleSelectWithValidatedInputParams<T>) {
            super(
                Object.assign(params, {
                    clearOnEmptyText: true,
                }),
            );
            Dom.place(this.validatedInput.errorDiv, this.node);
        }

        override buildTextBox(textBoxParams: TextBox.Params): void {
            this.validatedInput = new Text(Object.assign(this.textParams, textBoxParams));
            this.registerDestroyable(this.validatedInput);
            this.tb = this.validatedInput.input;
        }

        isValid(): boolean {
            return this.validatedInput.isValid();
        }

        override onFilter(): void {
            // If user enters other text different from selected entry, de-select the selected entry.
            if (this._selected && this.getTextValue().trim() !== this.display(this.getSelected())) {
                this.unselect(undefined, true);
            }
        }

        protected override shouldShowNoResultsDiv(): boolean {
            return false;
        }
    }
}

export = SingleSelect;
