/**
 * Two functions for grouping together UI.FocusContainerWidgets (or similar -- see FocusGroupable).
 *
 * Similar to how an FCW prevents onBlur calls if focus moves from one child/descendant of the FCW
 * to another, unite() and adopt() define two more "relationships" between FCWs that affect onBlurs.
 *
 * These functions both suppress and add onBlur calls as needed to create these "relationships".
 */
import Dom = require("Everlaw/Dom");
import Util = require("Everlaw/Util");

/**
 * Connects two FocusGroupables as if they were one FCW: onBlur happens to both if and only if focus
 * is leaving both FocusGroupables. That is,
 *
 * - If focus moves from A to B (or vice versa), neither is blurred.
 * - If focus moves from either one to somewhere else, both are blurred.
 *
 * Actually, this is more than just "pairing" -- it can be used to create arbitrarily-large "groups"
 * if called repeatedly, e.g. `unite(x, y); unite(y, z)` creates a group {x, y, z}.
 */
export function unite(a: FocusGroupable, b: FocusGroupable) {
    const aGroup = getFocusGroup(a);
    const bGroup = getFocusGroup(b);

    aGroup.members = Util.union(aGroup.members, bGroup.members);
    aGroup.children = Util.union(aGroup.children, bGroup.children);

    updateSelfReferences(bGroup, aGroup);

    replaceBlurHandler(a);
    replaceBlurHandler(b);
}

/**
 * When uniting two FocusGroups, one will get subsumed by the other. All references to the "subsumed"
 * group need to be updated to point to the other.
 */
function updateSelfReferences(subsumed: FocusGroup, other: FocusGroup) {
    subsumed.members.forEach((widget) => {
        widget._focusGroup = other;
    });
    subsumed.children.forEach((widget) => {
        widget.parent = other;
    });
}

/**
 * Creates a "parent-child" relationship between two FocusGroupables:
 *
 * - If focus moves from parent to child, the parent is not blurred.
 * - If focus moves from child to parent, the child *is* blurred.
 * - If focus moves from either one to somewhere else, both are blurred.
 *
 * This function respects the "groups" that are created with unite().
 *
 * TODO: "Grandparent" relationships currently do not work. If you need this, it should be
 * reasonably simple to implement (modify replaceBlurHandler to walk parent pointers instead of just
 * looking at the immediate parent).
 */
export function adopt(parent: FocusGroupable, child: FocusGroupable) {
    const parentGroup = getFocusGroup(parent);
    const childGroup = getFocusGroup(child);

    parentGroup.children.add(childGroup);
    childGroup.parent = parentGroup;

    replaceBlurHandler(parent);
    replaceBlurHandler(child);
}

/**
 * A FocusContainerWidget or other widget that uses dijit__FocusMixin.
 */
export interface FocusGroupable {
    /**
     * Do not access this property directly. Use `getFocusGroup(widget)` instead of `widget._focusGroup`.
     * See that function for details.
     */
    _focusGroup?: FocusGroup;
    onBlur: Function;
    getNode: () => HTMLElement;
    /**
     * Before using FocusGrouping, what was my "original" onBlur?
     */
    _onBlurPreFocusGroup?: Function;
}

/**
 * Get the widget's FocusGroup, creating it if it doesn't exist.
 */
function getFocusGroup(widget: FocusGroupable) {
    if (widget._focusGroup) {
        return widget._focusGroup;
    } else {
        const members = new Set<FocusGroupable>();
        members.add(widget);
        const g = new FocusGroup(members, new Set());
        widget._focusGroup = g;
        return g;
    }
}

class FocusGroup {
    members: Set<FocusGroupable>;
    children: Set<FocusGroup>;
    parent?: FocusGroup;

    constructor(members: Set<FocusGroupable>, children: Set<FocusGroup>) {
        this.members = members;
        this.children = children;
    }
    /**
     * Returns true if and only if:
     * - `node` is a descendant (in the DOM sense) of any member of this FocusGroup, OR
     * - `node` is a descendant (in the DOM sense) of any descendant (in the FocusGroup.children
     *   sense) of this FocusGroup.
     */
    contains(node: HTMLElement) {
        let foundAncestor = false;
        this.members.forEach((member) => {
            if (member.getNode()?.contains(node)) {
                foundAncestor = true;
            }
        });
        this.children.forEach((child) => {
            if (child.contains(node)) {
                foundAncestor = true;
            }
        });
        return foundAncestor;
    }
    /**
     * Blur every member of this FocusGroup and all ancestors (& their members) recursively.
     */
    propagateBlur() {
        this.members.forEach((fcw) => {
            fcw._onBlurPreFocusGroup?.();
        });
        this.children.forEach((g) => {
            g.propagateBlur();
        });
    }
}

function replaceBlurHandler(me: FocusGroupable) {
    me._onBlurPreFocusGroup = me._onBlurPreFocusGroup || me.onBlur.bind(me);

    me.onBlur = (force?: boolean) => {
        // setTimeout 0 is necessary because document.activeElement is not set until after
        // the blur event. (Standards are apparently vague on this, but there's some
        // discussion here: https://bugzilla.mozilla.org/show_bug.cgi?id=452307 )
        //
        // It would be preferable to use event.target, (which would not require the
        // setTimeout) but for some reason Dojo (which is ultimately the caller of this blur
        // handler) does not pass the event.
        //
        // Using Chrome, you might be tempted to use window.event.target, but this is
        // nonstandard and unsupported by Firefox.
        //
        // So we must use document.activeElement with the setTimeout.
        setTimeout(() => {
            const myFocusGroup = getFocusGroup(me);
            const newFocus = document.activeElement; // the newly-focused element
            if (!force && newFocus === null) {
                // In IE 11, sometimes document.activeElement gets set to null when in-focus
                // elements are modified. This happens when the DateBox onChange handler fires.
                // In this case, blur events should be suppressed in focus groups because
                // the user is still interacting with the DateBox.
                return;
            }
            if (
                !force
                && newFocus instanceof HTMLElement
                && myFocusGroup.contains(newFocus)
                && !Dom.isHidden(me)
            ) {
                // Need to check if hidden to force blur event to propogate in Firefox, since it
                // sometimes hides the focused UI element without blurring.
                return;
            }
            myFocusGroup.propagateBlur();

            // If we blur out of `me` and focus does *not* move to the parent FocusGroup, then we
            // should also blur the parent.
            if (newFocus instanceof HTMLElement) {
                const parent = myFocusGroup.parent;
                if (parent && !parent.contains(newFocus)) {
                    parent.propagateBlur();
                }
            }
        }, 0);
    };
}
